body {
    background-color: WhiteSmoke;
    font-family: 'Open Sans', sans-serif;
}

h1 {
    font-size: 32px;
    text-align: center;
    color: #8F6DF5;
    font-weight: 300;
    margin-bottom: 0px;
    margin-top: 20px;
}

h2 {
    font-size: 20px;
    font-weight: 300;
    opacity: 0.5;
    text-align: center;
    margin-top: 8px;
}

h3 {
    font-size: 18px;
    font-weight: 200;
    opacity: 0.5;
    text-align: center;
    margin-top: 4px;
    margin-bottom: 10px;
    max-width: 840px;
    width: 100%; 
    margin-left: auto; 
    margin-right: auto; 
    display: block; 
}


.brand {
    max-width: 168px;
    padding-top: 5px;
    margin: 0 auto;
    display: block;
}

img {
    max-width: 100%;
    
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile+label {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.25;
    padding: 6px 15px 7px;
    color: #8f6df5;
    border-radius: 50px;
    background-color: transparent;
    display: inline-block;
    border: 2px solid #D2C5FB;
    cursor: pointer;
    /* "hand" cursor */
    transition: all 0.3s ease;
}

.inputfile:focus+label,
.inputfile+label:hover {
    background-color: #8f6df5;
    color: white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.20);
    border: 2px solid #8f6df5;
}

.buttons {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.btn-primary {
    background-color: #8f6df5;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 50px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
    padding: 3px 20px 7px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease;
    vertical-align: middle;
}

.duplicate svg,
.remove svg {
    transform: translate(0px, 6px);
}

#btnCrop svg,
#btnPrint svg {
    transform: translate(-2px, 6px);
}

.btn-primary:hover {
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.20);
    background-color: #9A7CF6;
}

.btn-primary:focus {
    outline: 0;
}

.btn-outlined {
    background-color: transparent;
    background-image: none;
    border: 2px solid #D2C5FB;
    border-radius: 50px;
    box-sizing: border-box;
    color: #8f6df5;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    padding: 6px 15px 7px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s ease;
    vertical-align: middle;
}

.btn-outlined:hover {
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.20);
    background-color: #8f6df5;
    border: 2px solid #8f6df5;
    color: white;
}

.btn-outlined:focus {
    outline: 0;
}

.remove,
.duplicate {
    transition: all 0.3s ease;
    opacity: 0;
    position: absolute;
}

.remove {
    right: 5px;
    top: 5px;
}

.duplicate {
    right: 80px;
    top: 5px;
}

#christmas-tag-preview:not(:first-of-type):hover button {
    opacity: 1;
}

.design-left {
    padding: 8px 9px 7px;
    margin: 10px;
    order:1;
}

.design-right {
    padding: 8px 9px 7px;
    margin: 10px;
    order:3;
}

#container #christmas-tag-preview .design-left,
#container #christmas-tag-preview .design-right {
    display: none;
}

#container {
    display: flex;
    flex-flow: row wrap;
    transition: all 0.3s ease;
    background-color: white;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.20);
    padding: 10px;
    min-height: 220px;
    max-width: 840px;
    margin: 50px auto;
    transition: all 0.3s ease;
}

.row{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
}


img[src=""] {
    display: none;
}

#christmas-tag-preview {
    color: #F8715B;
    width: 400px;
    height: 200px;
    aspect-ratio: 2 / 1;
    box-shadow: 0px 8px 20px 0 rgba(0, 0, 0, 0.20);
    border-radius: 10px;
    background-image: url("../images/designs/bg_1.png");
    background-size: cover;
    display: flex;
    align-items: center;
    margin: 10px;
    position: relative;
    box-sizing: border-box;
    order:2;
}

#christmas-tag-preview:first-of-type .remove, #christmas-tag-preview:first-of-type .duplicate{
    display: none;
} 

p.name {
    font-family: 'Pacifico', cursive;
    font-size: 45px;
    margin-left: 9px;
    width: 200px;
    vertical-align: middle;
    text-align: center;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
}

.result-img {
    margin-left: 34px;
    height: 132px;
    width: 132px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
}

#canvas {
    margin-left: 34px;
    height: 132px;
    width: 132px;
    background-color: lightgrey;
    border-radius: 50%;
    cursor: default;
    border: none;
}

.cropper-crop-box {
    border-radius: 50% !important;
    transform: none !important;
}

.cropper-canvas {
    border-radius: 50% !important;
    transform: none !important;
    width: 132px !important;
    height: 132px !important;
    display: none !important;
}

.cropper-container {
    margin-left: 34px;
    width: 132px !important;
    height: 132px !important;
}

.cropper-view-box {
    border-radius: 50% !important;
    outline: none !important;
}

.cropper-face {
    background-color: transparent !important;
}

#competititon {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 18px 8px 15px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.20);
    border-radius: 8px;
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: white;
    color: #1565C1;
}

#competititon img {
    width: auto;
    height: 78px;
    margin-right: 10px;
}

#competititon p {
    width: 100%;
    margin-top: 0px;
    margin-bottom: 8px;
    color: black;
    opacity: 0.5;
}

#competition-desc {
    margin-left: 10px;
}

.text-link {
    color: black;
}

@media only screen and (max-width: 850px) {
    .hidden-mobile {
        display: none;
    }
    h1 {
        margin-top: 32px;
        padding-left: 10px;
        padding-right: 10px;
    }
    h2 {
        padding-left: 10px;
        padding-right: 10px;
    }
    #competititon {
        right: calc(50% - 120px) !important;
    }
    .brand {
        position: relative;
    }
}

@media only screen and (max-width: 567px) {
    .design-right {
        order:2;
    }
    #christmas-tag-preview{
        order:3;
    }
    .buttons {
        margin-bottom: 8px;
    }
}

@media only screen and (min-width: 851px) {
    .hidden-desktop {
        display: none;
    }
}


/* Print CSS */

@page {
    size: A4;
    margin: 3mm;
}

@media print {
    * {
        box-shadow: none !important;
    }
    h1,
    h2,
    h3,
    input,
    .buttons,
    .center {
        display: none;
    }
    #christmas-tag-preview:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    #christmas-tag-preview {
        display: inline-block !important;
        width: 90mm; /* Adjust width if needed */
        height: 45mm;
        margin: 0px;
        border: 0.2mm solid #D8D8D8;
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        break-inside: avoid; /* Prevent breaking inside an item */
        page-break-inside: avoid; /* For older browsers */
        break-before: auto; /* Allow automatic page breaks */
    }

    #result {
        display: none;
    }

    .result-img {
        margin-left: 7.65mm;
        width: 29.7mm;
        height: 29.7mm;
        display: inline-block;
        vertical-align: middle;
    }
    #container {
        padding: 0mm;
        /* margin: 0mm; */
        margin-top: 5mm;
        margin-bottom: 5mm;
        margin-left: 0;
        margin-right: 0;
        display: grid;
        grid-template-columns: repeat(2, 90mm); /* 2 columns layout */
        gap: 0mm; /* Adjust gap between columns if needed */
    }
    .brand,
    #competition,
    #competition-link {
        display: none;
    }
    p.name {
        font-size: 9.67mm;
        margin-left: 2.02mm;
        width: 45mm;
        display: inline-block;
        vertical-align: middle;
    }
    /*  footer.onlyprint {
        position: fixed;
        bottom: 0;  Because it's footer
    }*/
}

header,
footer {
    display: none;
}


/* END of Print CSS */
